<template>
    <div class="body" :style="info.exhibition_images ? 'background: url(https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + info.exhibition_images[0] + ')no-repeat #000;background-size: 100vw;' : ''">
        <div class="list">
            <div class="list-content">
                <div class="list-content-matter">
                    <div class="list-content-matter-title">{{info.name}}</div>
                    <div class="list-content-matter-star">
                        <div class="list-content-matter-star-img">
                            <van-rate v-model="info.show_star"
                                      :size="16"
                                      color="#DDA350"
                                      void-icon="star"
                                      void-color="#eee" readonly
                                      allow-half
                            />
                        </div>
                        <div class="list-content-matter-star-text">{{info.show_star}}分</div>
                    </div>
                    <div class="list-content-matter-label">
                        <div v-for="item,index in info.label" :key="index">{{item}}</div>
                    </div>

                    <!--在售套餐-->
                    <div v-if="info.online.length>0">
                        <div class="store-title">
                            <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon2022051402.png"
                                 class="store-title-img">
                            <div class="store-title-text">在售套餐</div>
                        </div>
                        <div class="store-goods" >
                            <div class="store-goods-img">
                                <img v-for="(items,index) in info.online" :key="index" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/'+items.images[0]" @click="ontoProduct(items.product_cell_id)">
                            </div>
                        </div>
                    </div>
                    <!--人气点评-->
                    <div v-if="info.lastComment.length>0">
                        <div class="store-title">
                            <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon2022051401.png"
                                 class="store-title-img">
                            <div class="store-title-text">人气点评</div>
                        </div>
                        <div class="store-comment" v-for="(item,index) in info.lastComment" :key="index">
                            <div class="store-comment-top">
                                <div class="store-comment-user">
                                    <img :src="item.headimgurl">
                                    <div>{{item.weixinNick}}</div>
                                </div>
                                <div class="store-comment-time">{{item.comment_time}}</div>
                            </div>
                            <div class="list-content-matter-star">
                                <div class="list-content-matter-star-img">
                                    <van-rate v-model="item.stars"
                                              :size="12"
                                              color="#DDA350"
                                              void-icon="star"
                                              void-color="#ccc" readonly
                                              allow-half
                                    />
                                </div>
                                <div class="list-content-matter-star-text">{{item.stars}}分</div>
                            </div>
                            <div class="store-comment-content">
                                {{item.comment}}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="bottom-button" @click="viewCommentList()">我要点评</div>
    </div>
</template>

<script>
  import { globalConfig } from '../../utils/mixin'

  export default {
    name: 'Comment',
    mixins: [globalConfig],
    data () {
      return {
        starEnv: 4,
        info: {
          online: [],
          lastComment: []
        }
      }
    },
    methods: {
      init: function () {
        this.axios.post(this.apiUrl + 'mall/top_rank/store', {
          id: this.$route.params.id
        }).then((response) => {
          console.log(response)
          this.info = response.data.info
        })
      },
      ontoProduct: function (id) {
          window.location.href = 'https://club.seetop.net.cn/#/product/' + id
      },
      viewCommentList: function () {
        this.$router.replace('/commentList/1?pid=' + this.info.business_id)
      }
    },
    created () {
      this.init()
    }
  }
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }

    .body {
        width: 100vw;
        min-height: 100vh;
        padding-bottom: 5vw;
        background: url("https://seetop-1257860468.file.myqcloud.com/seetop/uploads/20220512/1652351942.jpeg") no-repeat #000;
        background-size: 100vw;
    }

    .list {
        padding-top: 50vw;
    }

    .list-content {
        width: 92vw;
        margin: 5vw 4vw 0;
        border-radius: 4vw;
        background: #ffffff;
        padding: 12px 0 12px 0;
    }

    .list-content-matter {
        width: 92vw;
    }

    .list-content-matter-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 18px;
        color: #333;
        padding: 2vw 4vw 0 4vw;
    }

    .list-content-matter-star {
        padding: 1.5vw 4vw 0 4vw;
        display: flex;
        align-items: center;
    }

    .list-content-matter-star-text {
        color: #DDA350;
        font-size: 10px;
        margin-left: 3vw;
        font-weight: 600;
    }

    .list-content-matter-label {
        display: flex;
        flex-wrap: wrap;
        margin: 0 4vw 2vw 4vw;
    }

    .list-content-matter-label div {
        height: 26px;
        line-height: 26px;
        color: #666;
        background: #ccc;
        border-radius: 5px;
        padding: 0 4vw;
        margin: 2vw 3vw 0 0;
        font-size: 13px;
        font-weight: 400;
    }

    .bottom-button {
        width: 92vw;
        margin: 6vw 4vw 2vw 4vw;
        height: 11vw;
        line-height: 11vw;
        border-radius: 11vw;
        text-align: center;
        color: #fff;
        background: #DDA350;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.25vw;
    }

    .store-title {
        display: flex;
        align-items: center;
        padding: 4vw 4vw 2vw;
    }

    .store-title-img {
        height: 5.4vw;
        width: 5.4vw;
        margin-right: 2vw;
    }

    .store-title-text {
        color: #333;
        font-size: 16px;
        font-weight: 600;
    }

    .store-goods {
        width: 92vw;
        overflow: hidden;
    }

    .store-goods-img {
        display: flex;
        overflow-x: scroll;
        margin-left: 4vw;
    }

    .store-goods-img img {
        width: 125px;
        margin-right: 10px;
        border-radius: 5px;
    }

    .store-comment {
        width: 84vw;
        margin: 0 4vw 3vw;
        padding-bottom: 3vw;
        border-radius: 5px;
        background: #F8F0E5;
    }

    .store-comment-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3vw 4vw 1vw;
    }

    .store-comment-user {
        display: flex;
        align-items: center;
    }

    .store-comment-time {
        font-size: 11px;
        color: #999;
    }

    .store-comment-user img {
        width: 6vw;
        height: 6vw;
        border-radius: 6vw;
    }

    .store-comment-user div {
        margin-left: 2vw;
        font-size: 13px;
        font-weight: 600;
    }

    .store-comment-content {
        color: #333;
        font-size: 13px;
        padding: 1vw 4vw;
        font-weight: 400;
    }

    .store-comment-img {
        margin: 0 4vw 2vw 4vw;
        display: flex;
        flex-wrap: wrap;
    }

    .store-comment-img img {
        width: 22.5vw;
        height: 22.5vw;
        margin: 2vw 2vw 0 0;
        border-radius: 1vw;
    }
</style>
